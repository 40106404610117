import { API_URL } from '../content/cms.service'

const cms = () => {
    if (typeof window !== 'undefined') {
        window.open(`${API_URL}/admin`, '_blank')
        window.location = '/';
      }
    
    return null;
}

export default cms
 